<template>
	<div class="item" :class="[detial.modelType,{'label-top': cusStyle.labelStyle.site == '上方','label-right': cusStyle.labelStyle.site == '右侧'}]" :id="detial.uuid" :style="{borderWidth: cusStyle.itemStyle.borderWidth + 'px',borderStyle: cusStyle.itemStyle.borderStyle,borderColor: cusStyle.itemStyle.borderColor,padding: cusStyle.itemStyle.paddingVertical + 'px ' + cusStyle.itemStyle.paddingHorizontal + 'px',marginBottom: cusStyle.itemStyle.interval + 'px',borderRadius: cusStyle.itemStyle.borderRadius + 'px',backgroundColor: cusStyle.itemStyle.backgroundColor}">
		<div class="label" v-show="detial.showLabel && detial.modelType != 'lc-divider'" :style="{width: cusStyle.labelStyle.width + 'px',textAlign: cusStyle.labelStyle.textAlign == '居左'?'left': cusStyle.labelStyle.textAlign == '居中'?'center':'right'}">
			<i v-if="detial.topic_required" class="required">*</i>
			<span class="txt" :style="{fontSize: cusStyle.labelStyle.fontSize + 'px',color: cusStyle.labelStyle.color,fontWeight: cusStyle.labelStyle.fontWight,fontStyle: cusStyle.labelStyle.fontStyle,textDecoration: cusStyle.labelStyle.textDecoration}">{{detial.topicName}}</span>
		</div>
		<div class="cont">
			<ybdInputM v-if="detial.modelType == 'lc-input'" :detial="detial" :cusStyle="cusStyle" />
			<ybdTextareaM v-else-if="detial.modelType == 'lc-textarea'" :detial="detial" :cusStyle="cusStyle" />
			<ybdRadioM v-else-if="detial.modelType == 'lc-radio'" :detial="detial" />
			<ybdCheckboxM v-else-if="detial.modelType == 'lc-checkbox'" :detial="detial" />
			<ybdSelectM v-else-if="detial.modelType == 'lc-select'" :detial="detial" :cusStyle="cusStyle" />
			<ybdNumberM v-else-if="detial.modelType == 'lc-number'" :detial="detial" :cusStyle="cusStyle" />
			<ybdDateM v-else-if="detial.modelType == 'lc-date'" :detial="detial" :cusStyle="cusStyle" />
			<ybdLocationM v-else-if="detial.modelType == 'lc-location'" :detial="detial" :cusStyle="cusStyle" />
			<ybdTableM v-else-if="detial.modelType == 'lc-table'" :detial="detial" />
			<ybdImageM v-else-if="detial.modelType == 'lc-image'" :detial="detial" />
			<ybdAttachmentM v-else-if="detial.modelType == 'lc-attachment'" :detial="detial" />
			<ybdDividerM v-else-if="detial.modelType == 'lc-divider'" :detial="detial" />
		</div>
	</div>
</template>
<script>
import ybdInputM from "@/components/ybdWidgetM/ybdInputM";
import ybdTextareaM from "@/components/ybdWidgetM/ybdTextareaM";
import ybdRadioM from "@/components/ybdWidgetM/ybdRadioM";
import ybdCheckboxM from "@/components/ybdWidgetM/ybdCheckboxM";
import ybdSelectM from "@/components/ybdWidgetM/ybdSelectM";
import ybdNumberM from "@/components/ybdWidgetM/ybdNumberM";
import ybdDateM from "@/components/ybdWidgetM/ybdDateM";
import ybdLocationM from "@/components/ybdWidgetM/ybdLocationM";
import ybdTableM from "@/components/ybdWidgetM/ybdTableM";
import ybdImageM from "@/components/ybdWidgetM/ybdImageM";
import ybdAttachmentM from "@/components/ybdWidgetM/ybdAttachmentM";
import ybdDividerM from "@/components/ybdWidgetM/ybdDividerM";
export default {
	components: {
		ybdInputM,
		ybdTextareaM,
		ybdRadioM,
		ybdCheckboxM,
		ybdSelectM,
		ybdNumberM,
		ybdDateM,
		ybdLocationM,
		ybdTableM,
		ybdImageM,
		ybdAttachmentM,
		ybdDividerM,
	},
	props: {
		detial: {
			type: Object,
		},
		cusStyle: {
			type: Object,
		},
	},
};
</script>
<style lang="less">
.mform-item{
	.item {
		display: flex;
		align-items: center;
		overflow: hidden;
		.label {
			max-width: 100%;
			.required {
				color: #f56c6c;
				margin-right: 4px;
			}
		}
		.cont {
			flex: 1;
			display: flex;
			margin: 0 0 0 10px;
			overflow: hidden;
		}
	}
	.item.textarea,
	.item.radio,
	.item.checkbox,
	.item.image{
		align-items: unset;
		.label{
			line-height: 40px;
		}
	}
	.item.divider{
		border: none!important;;
		.cont{
			margin: 0;
		}
	}
	.item.label-top{
		flex-direction: column;
		align-items: unset;
		.label{
			line-height: 1.5;
		}
		.cont{
			margin: 5px 0 0;
		}
	}
	.item.label-right{
		flex-direction: row-reverse;
		.label{
			line-height: 1.5;
		}
		.cont{
			margin: 0 10px 0 0;
		}
	}
}
</style>