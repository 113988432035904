
<template>
	<el-upload class="ybd_m_upload" ref="fileUpload" action="/com/uplaod_modelimg.do" :before-upload="beforeImgUpload"  :on-exceed="masterFileMax" :limit="detial.allowNum" :file-list="detial.value" :on-success="handleImgSuccess">
		<el-button type="primary">
			<i class="el-icon-upload" v-if="detial.inputStyle.isIcon"></i>
			<span class="txt">{{detial.inputStyle.text}}</span>
		</el-button>
	</el-upload>
</template>
<script>
export default {
	props: {
		detial: {
			type: Object,
		},
	},
	data() {
			return {
				formToken: sessionStorage.getItem('token')?sessionStorage.getItem('token'):'',
			};
	},
	methods: {
		// 上传前的校验
		beforeImgUpload(file){
			const isLt5M = file.size / 1024 / 1024 < this.detial.allowSize;
			if (!isLt5M) {
				this.$message.error(`大小不能超过 ${this.detial.allowSize}MB!`);
			}
			return isLt5M;
		},
		masterFileMax() {
			this.$message.error(`请最多上传 ${this.detial.allowNum} 个文件。`);
		},
		// 上传成功回调
		handleImgSuccess(){
			// this.detial.value = res.data.modelimg;
			this.detial.value = this.$refs.fileUpload.uploadFiles;
		}
	}
};
</script>
<style lang="less" scoped>
::v-deep .ybd_m_upload {
		.el-upload {
			.el-button {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0;
				border-color: var(--theme-custom-color);
				background-color: var(--theme-custom-color);
			}
		}
	}
</style>