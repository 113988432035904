<template>
	<el-input class="ybd_m_input" :placeholder=" detial.placeholder" v-model="detial.value"></el-input>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
		cusStyle: {
			type: Object,
		},
	},
	watch: {
		"cusStyle.inputStyle": {
			handler() {
				this.$nextTick(function () {
					const styleStr = this.cusStyle.inputStyle;
					$(".ybd_m_input .el-input__inner").css({
						height: styleStr.height + "px",
						borderWidth: styleStr.borderWidth + "px",
						borderStyle: styleStr.borderStyle,
						borderColor: styleStr.borderColor,
						borderRadius: styleStr.borderRadius + "px",
						backgroundColor: styleStr.backgroundColor,
					});
				});
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
<style lang="less" scoped>
	.ybd_m_input{
		::v-deep .el-input__inner{
			padding: 0 10px;
			font-size: 12px;
		}
	}
</style>