<template>
	<div class="item" :id="detial.uuid" :style="{height: 'calc( 100% - ' + detial.itemStyle.interval + 'px )',borderWidth: detial.itemStyle.borderWidth + 'px',borderStyle: detial.itemStyle.borderStyle,borderColor: detial.itemStyle.borderColor,padding: detial.itemStyle.paddingVertical + 'px ' + detial.itemStyle.paddingHorizontal + 'px',marginBottom: detial.itemStyle.interval + 'px',borderRadius: detial.itemStyle.borderRadius + 'px',backgroundColor: detial.itemStyle.backgroundColor}">
		<div class="label" v-show="detial.showLabel" :style="{width: detial.labelStyle.width + 'px',textAlign: detial.labelStyle.textAlign == '居左'?'left': detial.labelStyle.textAlign == '居中'?'center':'right'}">
			<i v-if="detial.topic_required" class="required">*</i>
			<span class="txt" :style="{fontSize: detial.labelStyle.fontSize + 'px',color: detial.labelStyle.color,fontWeight: detial.labelStyle.fontWight,fontStyle: detial.labelStyle.fontStyle,textDecoration: detial.labelStyle.textDecoration}">{{detial.topicName}}</span>
		</div>
		<div class="cont">
			<el-cascader class="ybd_location" popper-class="ybd-cus-location" :options="options" :placeholder="detial.placeholder" v-model="detial.value" separator="" :clearable="true"></el-cascader>
		</div>
	</div>
</template>
<script>
import $ from "jquery";
import { regionData  } from 'element-china-area-data'
export default {
	props: {
		detial: {
			type: Object,
		},
	},
	data() {
		return {
			options: regionData
		};
	},
	watch: {
		"detial.inputStyle": {
			handler() {
				this.$nextTick(function () {
					const styleStr = this.detial.inputStyle;
					$("#" + this.detial.uuid + " .ybd_location .el-input__inner").css({
						height: styleStr.height + "px",
						borderWidth: styleStr.borderWidth + "px",
						borderStyle: styleStr.borderStyle,
						borderColor: styleStr.borderColor,
						borderRadius: styleStr.borderRadius + "px",
						backgroundColor: styleStr.backgroundColor,
					});
				});
			},
			deep: true,
			immediate: true,
		},
	}
};
</script>
<style lang="less" scoped>
.item {
    display: flex;
    align-items: center;
    .label {
        max-width: 100%;
        .required {
            color: #f56c6c;
            margin-right: 4px;
        }
    }
    .cont {
        flex: 1;
        display: flex;
        margin: 0 0 0 10px;
        .el-cascader {
            width: 100%;
        }
    }
}
</style>