<template>
	<el-input v-if="detial.allowDecimal" class="ybd_m_number" type="number" step="0.1" :placeholder=" detial.placeholder" v-model="detial.value" @blur="detial.value = $event.target.value"></el-input>
	<el-input v-else class="ybd_m_number" type="number" :placeholder=" detial.placeholder" v-model="detial.value" @blur="detial.value = $event.target.value"></el-input>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
		cusStyle: {
			type: Object,
		},
	},
	watch: {
		"cusStyle.inputStyle": {
			handler() {
				this.$nextTick(function () {
					const styleStr = this.cusStyle.inputStyle;
					$(".ybd_m_number .el-input__inner").css({
						height: styleStr.height + "px",
						borderWidth: styleStr.borderWidth + "px",
						borderStyle: styleStr.borderStyle,
						borderColor: styleStr.borderColor,
						borderRadius: styleStr.borderRadius + "px",
						backgroundColor: styleStr.backgroundColor,
					});
				});
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>