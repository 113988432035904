<template>
    <ybdInput v-if="detial.modelType === 'lc-input'" :detial="detial" />
    <ybdTextarea v-else-if="detial.modelType === 'lc-textarea'" :detial="detial" />
    <ybdRadio v-else-if="detial.modelType === 'lc-radio'" :detial="detial" />
    <ybdCheckbox v-else-if="detial.modelType === 'lc-checkbox'" :detial="detial" />
    <ybdSelect v-else-if="detial.modelType === 'lc-select'" :detial="detial" />
    <ybdNumber v-else-if="detial.modelType === 'lc-number'" :detial="detial" />
    <ybdDate v-else-if="detial.modelType === 'lc-date'" :detial="detial" />
    <ybdLocation v-else-if="detial.modelType === 'lc-location'" :detial="detial" />
    <ybdTable v-else-if="detial.modelType === 'lc-table'" :detial="detial" />
    <ybdImage v-else-if="detial.modelType === 'lc-image'" :detial="detial" />
    <ybdAttachment v-else-if="detial.modelType === 'lc-attachment'" :detial="detial" />
    <ybdDivider v-else-if="detial.modelType === 'lc-divider'" :detial="detial" />
</template>
<script>
import ybdInput from "@/components/ybdWidget/ybdInput";
import ybdTextarea from "@/components/ybdWidget/ybdTextarea";
import ybdRadio from "@/components/ybdWidget/ybdRadio";
import ybdCheckbox from "@/components/ybdWidget/ybdCheckbox";
import ybdSelect from "@/components/ybdWidget/ybdSelect";
import ybdNumber from "@/components/ybdWidget/ybdNumber";
import ybdDate from "@/components/ybdWidget/ybdDate";
import ybdLocation from "@/components/ybdWidget/ybdLocation";
import ybdTable from "@/components/ybdWidget/ybdTable";
import ybdImage from "@/components/ybdWidget/ybdImage";
import ybdAttachment from "@/components/ybdWidget/ybdAttachment";
import ybdDivider from "@/components/ybdWidget/ybdDivider";
export default {
	components: {
		ybdInput,
		ybdTextarea,
		ybdRadio,
		ybdCheckbox,
		ybdSelect,
		ybdNumber,
		ybdDate,
		ybdLocation,
		ybdTable,
		ybdImage,
		ybdAttachment,
		ybdDivider,
	},
	props: {
		detial: {
			type: Object,
		},
	}
};
</script>
<style lang="less">
.set_item {
    .title {
			display: flex;
			align-items: center;
			height: 58px;
			padding: 0 24px;
			border-bottom: 1px solid #e5e5e5;
			font-size: 16px;
			white-space: nowrap;
    }
    .list {
        flex: 1;
        overflow-y: auto;
        padding: 0 10px;
        .item {
            padding: 0 5px;
            border-bottom: 1px solid #eee;
            .switch_btn {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 50px;
                .label {
                    display: flex;
                    align-items: center;
                }
                .fold_btn {
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                    color: #999;
                    cursor: pointer;
                    .iconfont {
                        font-size: 14px;
                        margin: 0 0 0 3px;
                        transition: all 0.2s;
                    }
                    &:hover {
                        color: var(--theme-color);
                    }
                }
                .fold_btn.active {
                    .iconfont {
                        margin-top: -3px;
                        transform: rotate(-180deg);
                        transition: all 0.2s;
                    }
                }
            }
            .sub_list {
                padding: 0 10px;
                margin: 0 0 10px;
                background-color: #f5f5f5;
                overflow: hidden;
                .sub_item {
                    display: flex;
                    justify-content: space-between;
                    padding: 10px 0;
                    border-top: 1px dashed #ddd;
                    .sub_label {
                        line-height: 28px;
                        color: #888;
                        font-size: 13px;
                    }
                    &:first-child {
                        border: none;
                    }
                }
            }
            .el-input__inner {
                width: 200px;
                height: 28px;
                font-size: 13px;
                padding: 0 5px;
            }
            .el-input__icon {
                line-height: 28px;
            }
            .el-color-picker {
                display: block;
                margin: 0 0 0 5px;
                border-radius: 2px;
                background-color: #fff;
            }
            .el-textarea {
                width: 200px;
                .el-textarea__inner {
                    padding: 5px;
                    font-family: inherit;
                    font-size: 13px;
                }
            }
            .avatar-uploader {
                .el-upload {
									display: flex;
									align-items: center;
									justify-content: center;
									width: 70px;
									height: 70px;
									border: 1px dashed #d9d9d9;
									border-radius: 3px;
									cursor: pointer;
									.avatar{
										width: 100%;
										height: 100%;
										position: relative;
										img{
											width: 100%;
											height: 100%;
											object-fit: contain;
										}
										.el-icon-error{
											font-size: 20px;
											background-color: #fff;
											color: #333;
											border-radius: 50%;
											position: absolute;
											top: -8px;
											right: -8px;
											z-index: 9;
										}
									}
									.avatar-uploader-icon {
										font-size: 28px;
										color: #8c939d;
									}
									&:hover {
											border-color: var(--theme-color);
									}
							}
            }
            .text_style {
                display: flex;
                .iconfont {
                    line-height: 28px;
                    margin: 0 0 0 5px;
                    color: #888;
                    cursor: pointer;
                }
                .iconfont.active {
                    color: var(--theme-color);
                }
                .el-input__inner {
                    width: 104px !important;
                }
            }
            .silder {
                width: 200px;
                padding: 0px 8px 0 0;
                .el-slider__runway {
                    margin: 11px 0;
                    .el-slider__bar {
                        background-color: var(--theme-color);
                    }
                    .el-slider__button {
                        border-color: var(--theme-color);
                    }
                }
            }
            .border_style {
                display: flex;
                .el-input__inner {
                    width: 60px;
                }
                .el-select {
                    .el-input__inner {
                        width: 102px;
                        margin: 0 0 0 5px;
                    }
                }
            }
        }
    }
}
</style>