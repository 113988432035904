<template>
    <div class="item" :id="detial.uuid" :style="{height: 'calc( 100% - ' + detial.itemStyle.interval + 'px )',borderWidth: detial.itemStyle.borderWidth + 'px',borderStyle: detial.itemStyle.borderStyle,borderColor: detial.itemStyle.borderColor,padding: detial.itemStyle.paddingVertical + 'px ' + detial.itemStyle.paddingHorizontal + 'px',marginBottom: detial.itemStyle.interval + 'px',borderRadius: detial.itemStyle.borderRadius + 'px',backgroundColor: detial.itemStyle.backgroundColor}">
        <el-divider class="ybd_divider" v-if="detial.showText" :content-position="detial.dividerTextPosition == '居左'?'left':detial.dividerTextPosition == '居中'?'center':'right'">{{detial.dividerText}}</el-divider>
        <el-divider class="ybd_divider" v-else></el-divider>

    </div>
</template>
<script>
import $ from "jquery";
export default {
    props: {
        detial: {
            type: Object,
        },
    },
    watch: {
        detial: {
            handler() {
                this.$nextTick(function () {
                    $("#" + this.detial.uuid + " .ybd_divider").css({
                        height: this.detial.size + "px",
                        backgroundColor: this.detial.dividerColor,
                        margin:
                            this.detial.marginVertical +
                            "px " +
                            this.detial.marginHorizontal +
                            "px",
                    });

                    $(
                        "#" +
                            this.detial.uuid +
                            " .ybd_divider .el-divider__text"
                    ).css({
                        fontSize: this.detial.fontSize + "px",
                        color: this.detial.color,
                        fontWeight: this.detial.fontWight,
                        fontStyle: this.detial.fontStyle,
                        textDecoration: this.detial.textDecoration,
                    });
                });
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
<style lang="less" scoped>
.item {
    display: flex;
    align-items: center;
    .ybd_divider {
        .el-divider__text {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 100%;
            top: 50%;
        }
    }
}
</style>