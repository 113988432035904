<template>
	<el-divider class="ybd_m_divider" v-if="detial.showText" :content-position="detial.dividerTextPosition == '居左'?'left':detial.dividerTextPosition == '居中'?'center':'right'">{{detial.dividerText}}</el-divider>
	<el-divider class="ybd_m_divider" v-else></el-divider>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
	},
	watch: {
		detial: {
			handler() {
				this.$nextTick(function () {
					$(".ybd_m_divider").css({
						height: this.detial.size + "px",
						backgroundColor: this.detial.dividerColor,
						margin: this.detial.marginVertical + "px " + this.detial.marginHorizontal + "px",
					});
					$(".ybd_m_divider .el-divider__text").css({
						fontSize: this.detial.fontSize + "px",
						color: this.detial.color,
						fontWeight: this.detial.fontWight,
						fontStyle: this.detial.fontStyle,
						textDecoration: this.detial.textDecoration,
					});
				});
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
<style lang="less" scoped>
.ybd_m_divider {
	.el-divider__text {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100%;
		top: 50%;
	}
}
</style>