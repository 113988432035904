<template>
	<el-radio-group class="ybd_m_radio" :class="{horizontal: detial.inputStyle.orientationMode == '纵向'}" v-model="detial.value">
		<el-radio v-for="(item,index) in detial.topicOption" :key="index" :label="index">{{item.value}}</el-radio>
	</el-radio-group>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
	},
	computed: {
		watchAttr() {
			return {
				...this.detial.inputStyle,
				options: this.detial.topicOption,
			};
		},
	},
	watch: {
		watchAttr: {
			handler() {
				this.$nextTick(function () {
					const styleStr = this.detial.inputStyle;
					if (styleStr.orientationMode == "横向") {
						$(".ybd_m_radio .el-radio").css({
							display: "inline-block",
							margin: "12px 0",
							marginRight: styleStr.intervalHorizontal + "px",
						});
					} else {
						$(".ybd_m_radio .el-radio").css({
							display: "block",
							margin: 0,
							marginBottom: styleStr.intervalVertical + "px",
						});
					}
				});
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
<style lang="less" scoped>
.ybd_m_radio{
	.el-radio {
		margin: 12px 30px 12px 0;
	}
}
.horizontal {
	.el-radio {
		&:last-child {
			margin-bottom: 0 !important;
		}
	}
}
</style>