<template>
	<div class="ybd_table_inner">
		<el-table class="ybd_table" :data="tableData" border>
			<el-table-column header-align="center" v-for="(item,index) in detial.topicOption" :label="item.value" :key="index">
				<template slot-scope="scope">
					<input :id="'mIinputR' + scope.$index + 'C' + index" class="el-input__inner" type="text" v-model="scope.row.value[index]" @blur="changeData">
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
	},
	computed: {
		tableData() {
			let arr = [];
			for (let i = 0; i < this.detial.rowNum; i++) {
				if(this.detial.value[i]){
					arr.push({value:this.detial.value[i].value});
				}else{
					arr.push({value:[]});
				}
				
			}
			return arr;
		},
		watchAttr() {
			return {
				...this.detial.inputStyle,
				cols: this.detial.topicOption,
				rowNum: this.detial.rowNum,
			};
		},
	},
	watch: {
		watchAttr: {
			handler() {
				this.$nextTick(function () {
					this.setStyle();
				});
			},
			deep: true,
			immediate: true,
		},
	},
	created() {
		this.setStyle();
	},
	methods: {
		setStyle() {
			const styleStr = this.detial.inputStyle;
			$("#" + this.detial.uuid + " .ybd_table th.el-table__cell .cell").css({
				fontSize: styleStr.fontSize + "px",
				color: styleStr.color,
				fontWeight: styleStr.fontWight,
				fontStyle: styleStr.fontStyle,
				textDecoration: styleStr.textDecoration,
				textAlign: styleStr.textAlign == "居左" ? "left" : styleStr.textAlign == "居中" ? "center" : "right",
			});
			$("#" + this.detial.uuid + " .ybd_table th.el-table__cell").css({
				backgroundColor: styleStr.backgroundColor,
			});
			$( "#" + this.detial.uuid + " .ybd_table .el-table__cell,#" + this.detial.uuid + " .ybd_table.el-table--border").css({
				borderColor: styleStr.borderColor,
			});
		},
		changeData(){
			this.detial.value = this.tableData;
		}
	},
};
</script>
<style lang="less" scoped>
.ybd_table_inner{
	width: 100%;
	overflow-x: auto;
	::v-deep .el-table th.el-table__cell > .cell {
		line-height: 1;
	}
	.el-table--border::after,
	.el-table--group::after,
	.el-table::before {
		background-color: unset;
	}
}
</style>