<template>

  <div class="item" :id="detial.uuid" :style="{height: 'calc( 100% - ' + detial.itemStyle.interval + 'px )',borderWidth: detial.itemStyle.borderWidth + 'px',borderStyle: detial.itemStyle.borderStyle,borderColor: detial.itemStyle.borderColor,padding: detial.itemStyle.paddingVertical + 'px ' + detial.itemStyle.paddingHorizontal + 'px',marginBottom: detial.itemStyle.interval + 'px',borderRadius: detial.itemStyle.borderRadius + 'px',backgroundColor: detial.itemStyle.backgroundColor}">
    <div class="label" v-show="detial.showLabel" :style="{textAlign: detial.labelStyle.textAlign == '居左'?'left': detial.labelStyle.textAlign == '居中'?'center':'right'}">
      <!-- <i v-if="detial.topic_required" class="required">*</i> -->
      <span class="txt" :style="{fontSize: detial.labelStyle.fontSize + 'px',color: detial.labelStyle.color,fontWeight: detial.labelStyle.fontWight,fontStyle: detial.labelStyle.fontStyle,textDecoration: detial.labelStyle.textDecoration}">{{detial.topicName}}</span>
    </div>
    <div class="cont">
      <el-table class="ybd_table" :data="tableData" border>
        <el-table-column header-align="center" v-for="(item,index) in detial.topicOption" :label="item.value" :key="index">
          <template slot-scope="scope">
            <input :id="'inputR' + scope.$index + 'C' + index" class="el-input__inner" type="text" v-model="scope.row.value[index]" @blur="changeData">
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  props: {
    detial: {
      type: Object,
    },
  },
  computed: {
    tableData() {
      let arr = [];
      for (let i = 0; i < this.detial.rowNum; i++) {
        if(this.detial.value[i]){
          arr.push({value:this.detial.value[i].value});
        }else{
          arr.push({value:[]});
        }
        
      }
      return arr;
    },
    watchAttr() {
      return {
        ...this.detial.inputStyle,
        cols: this.detial.topicOption,
        rowNum: this.detial.rowNum,
      };
    },
  },
  watch: {
    watchAttr: {
      handler() {
        this.$nextTick(function () {
          this.setStyle();
        });
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.setStyle();
  },
  methods: {
    setStyle() {
      const styleStr = this.detial.inputStyle;
      $(
        "#" + this.detial.uuid + " .ybd_table th.el-table__cell .cell"
      ).css({
        fontSize: styleStr.fontSize + "px",
        color: styleStr.color,
        fontWeight: styleStr.fontWight,
        fontStyle: styleStr.fontStyle,
        textDecoration: styleStr.textDecoration,
        textAlign:
          styleStr.textAlign == "居左"
            ? "left"
            : styleStr.textAlign == "居中"
            ? "center"
            : "right",
      });
      $("#" + this.detial.uuid + " .ybd_table th.el-table__cell").css({
        backgroundColor: styleStr.backgroundColor,
      });
      $(
        "#" +
          this.detial.uuid +
          " .ybd_table .el-table__cell,#" +
          this.detial.uuid +
          " .ybd_table.el-table--border"
      ).css({
        borderColor: styleStr.borderColor,
      });
    },
    changeData(){
      this.detial.value = this.tableData;
    }
  },
};
</script>
<style lang="less" scoped>
.item {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  .label {
    width: 100%;
    padding: 10px 0;
  }
  .cont {
    width: 100%;
    ::v-deep .el-table th.el-table__cell > .cell {
      line-height: 1;
    }
    .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      background-color: unset;
    }
  }
}
</style>