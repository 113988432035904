<template>
	<el-input class="ybd_m_textarea" type="textarea" resize="none" :rows="detial.inputStyle.rows" :placeholder="detial.placeholder" v-model="detial.value"></el-input>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
		cusStyle: {
			type: Object,
		},
	},
	watch: {
		"cusStyle.inputStyle": {
			handler() {
				this.$nextTick(function () {
					const styleStr = this.cusStyle.inputStyle;
					$(".ybd_m_textarea .el-textarea__inner").css({
						borderWidth: styleStr.borderWidth + "px",
						borderStyle: styleStr.borderStyle,
						borderColor: styleStr.borderColor,
						borderRadius: styleStr.borderRadius + "px",
						backgroundColor: styleStr.backgroundColor,
					});
				});
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
<style lang="less" scoped>
	::v-deep .el-textarea__inner{
		padding: 5px 10px;
		font-size: 12px;
	}
</style>