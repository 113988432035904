<template>
	<div class="date-inner" :class="{'ico-right': detial.inputStyle.iconSite == '居右'}">
		<template v-if="detial.isCurrent">
			<el-date-picker class="ybd_m_date" popper-class="ybd-cus-date" :value="new Date()" :type="detial.dataType" :placeholder="detial.placeholder" :format="currentFormat" :editable="false" :clearable="false" readonly></el-date-picker>
		</template>
		<template v-else>
			<template v-if="detial.isRang">
				<el-date-picker class="ybd_m_date" popper-class="ybd-cus-date" :range-separator="detial.valueFormat == '文字'?'至':'-'" v-model="detial.value" :type="detial.rangDataType" :start-placeholder="detial.startPlaceholder" :end-placeholder="detial.endPlaceholder" :format="rangFormat" :editable="false" :clearable="false"></el-date-picker>
			</template>
			<template v-else>
				<el-date-picker class="ybd_m_date" popper-class="ybd-cus-date" v-model="detial.value" :placeholder="detial.placeholder" :type="detial.dataType" :format="currentFormat" :editable="false" :clearable="false"></el-date-picker>
			</template>
		</template>
	</div>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
				type: Object,
		},
		cusStyle: {
			type: Object,
		},
	},
	computed: {
		currentFormat() {
			let formatStr = "";
			switch (this.detial.dataType) {
				case "date":
					formatStr = this.detial.valueFormat == "文字" ? "yyyy年MM月dd日" : "yyyy-MM-dd";
					break;
				case "week":
					formatStr = this.detial.valueFormat == "文字" ? "yyyy年 第WW周" : "yyyy WW'th' week";
					break;
				case "month":
					formatStr = this.detial.valueFormat == "文字" ? "yyyy年MM月" : "yyyy-MM";
					break;
				case "year":
					formatStr = this.detial.valueFormat == "文字" ? "yyyy年" : "yyyy";
					break;
				case "time":
					formatStr = this.detial.valueFormat == "文字" ? "HH时mm分ss秒" : "HH:mm:ss";
					break;
				default:
					formatStr = this.detial.valueFormat == "文字" ? "yyyy年MM月dd日 HH时mm分ss秒" : "yyyy-MM-dd HH:mm:ss";
					break;
			}
			return formatStr;
		},
		rangFormat() {
			let formatStr = "";
			switch (this.detial.rangDataType) {
				case "daterange":
					formatStr = this.detial.valueFormat == "文字" ? "yyyy年MM月dd日" : "yyyy-MM-dd";
					break;
				default:
					formatStr = this.detial.valueFormat == "文字" ? "yyyy年MM月dd日 HH时mm分ss秒" : "yyyy-MM-dd HH:mm:ss";
					break;
				}
			return formatStr;
		},
		watchAttr() {
			return {...this.cusStyle.inputStyle, isRang: this.detial.isRang, isCurrent: this.detial.isCurrent};
		},
	},
	watch: {
		watchAttr: {
			handler() {
				this.$nextTick(function () {
					const styleStr = this.cusStyle.inputStyle;
					$(".ybd_m_date.el-range-editor,.ybd_m_date .el-input__inner").css({
						height: styleStr.height + "px",
						borderWidth: styleStr.borderWidth + "px",
						borderStyle: styleStr.borderStyle,
						borderColor: styleStr.borderColor,
						borderRadius: styleStr.borderRadius + "px",
						backgroundColor: styleStr.backgroundColor,
					});
				});
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
<style lang="less" scoped>
	.date-inner {
		display: flex;
		width: 100%;
		::v-deep .el-date-editor.el-input,
		.el-date-editor.el-input__inner {
				width: 100%;
		}
		::v-deep .el-range-editor {
			.el-range-separator {
				display: flex;
				height: 100%;
				align-items: center;
				justify-content: center;
			}
			.el-range-input {
				flex: 1;
				background-color: transparent;
				border-radius: inherit;
			}
			.el-range__close-icon{
				display: none;
			}
		}
		::v-deep .el-input--suffix .el-input__inner{
			padding-right: 10px;
		}
	}
	.date-inner.ico-right {
		::v-deep .el-input--prefix .el-input__inner {
			padding-left: 10px;
		}
		::v-deep .el-input__prefix {
			left: auto;
			right: 5px;
		}
		::v-deep .el-date-editor {
			.el-range__icon {
				position: absolute;
				right: 5px;
			}
		}
	}
</style>